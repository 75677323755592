import { Link } from 'gatsby'
import React from 'react'
import {
    FacebookShareButton,
    LinkedinShareButton,
    TwitterShareButton,
    WhatsappShareButton
} from "react-share";
import {
    EmailIcon,
    FacebookIcon,
    LinkedinIcon,
    TwitterIcon,
    WhatsappIcon
} from "react-share";
import moment from 'moment';
import { Container, Row, Col } from 'react-bootstrap'
import parse from "html-react-parser"
import './AreaContent.scss'
import AreaImg from "../../images/area-content-img.png"
import ContactDetails from '../ContactDetails/ContactDetails'
import RecentNews from '../RecentNews/RecentNews'
import { AnimationOnScroll } from 'react-animation-on-scroll';
import Newsletter from "../forms/newsletter"
import PlayVideo from "../Play/PlayVideo";

import ImageTransform from "../../common/ggfx-client/module/components/image-transform";


const AreaContent = (props) => {
    const shareurl = typeof window !== 'undefined' ? window.location.href : ''
    const trackerShare = (event) => {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            'event': 'Share - social icons',
            'formType': event + ' - ' + shareurl,
            'formId': 'Share',
            'formName': 'Share',
            'formLabel': 'Share'
        });
    }

    
  let processedImages = JSON.stringify({});
  if (props.imagetransforms?.Banner_image_Transforms) {
    processedImages = props.imagetransforms.Banner_image_Transforms;
  }
// console.log(props?.imagetransforms)

    return (
        <AnimationOnScroll animateIn="animate__fadeIn" animateOnce={true}>
            <div className='area-content news-details'>
                <Container>
                    <Row>
                        <Col md={7}>
                            <div className='news-banner'>
                                <h1>{props.title}</h1>
                                <div className='date'>
                                    <span className='data-card'>{moment(props.post_on).format("DD MMMM YYYY")} {props.category ? ' / ' + props.category : ''}</span>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <div className='main-wrapper'>
                                <div className='lft-content'>
                                    {props.banner_image &&
                                        <div className="img-bg-video">
                                            <ImageTransform imagesources={props.banner_image?.url} renderer="srcSet" imagename="blog.Banner_image.detail" attr={{ alt: `${props.title} - Arabian Estates`, class: '' }} imagetransformresult={processedImages} id={props.id} format="source" />

                                            {/* <img src={props.banner_image.url} alt /> */}
                                            {props.video_url &&
                                                <PlayVideo url={props.video_url} />
                                            }
                                        </div>}
                                    {props?.description &&
                                        <div className='news-discription'>
                                            {parse(props.description)}
                                        </div>
                                    }

                                    <div className='social-menu'>
                                        <div className='footer-icons'>
                                            <ul>
                                                {/* <li>
                                                    <Link to="/" className="social-icon">
                                                        <i className="icon-insta-blue"></i>
                                                    </Link>
                                                </li> */}
                                                <li>
                                                    <TwitterShareButton onClick={() => trackerShare('TwitterShareButton')} url={shareurl} className="my-share-button twitter-share social-icon">
                                                        <i className="icon-twitter-blue"></i>
                                                    </TwitterShareButton>
                                                </li>
                                                <li>
                                                    <FacebookShareButton onClick={() => trackerShare('FacebookShareButton')} url={shareurl} className="my-share-button facebook-share social-icon">
                                                        <i className="icon-facebook-blue"></i>
                                                    </FacebookShareButton>
                                                </li>
                                                <li>
                                                    <LinkedinShareButton onClick={() => trackerShare('LinkedinShareButton')} url={shareurl} className="my-share-button linkedin-share social-icon">
                                                        <i className="icon-linkedin-blue"></i>
                                                    </LinkedinShareButton>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className='rgt-content'>
                                    <div className='news-box'>
                                        <RecentNews {...props} />
                                        <div className='email-box'>
                                            <div className='box-content'>
                                                <div className='box-data'>
                                                    <h4>The easiest way to stay up to date with Arabian Estates...</h4>
                                                    <p>Don’t worry, we’ll never share your email or spam.</p>
                                                </div>
                                                {/* <div className='email-wrapper'> */}
                                                    <Newsletter />
                                                    {/* <div className='form-block'>
                                                        <input type="text" placeholder='Email Address' className='form-control' />
                                                    </div>
                                                    <button class="search-btn"><i class="icon-arrow"></i></button> */}
                                                {/* </div> */}
                                                <div className='privacy-policy'>
                                                    By clicking Submit, you agree to our <Link className='policy-link' to="/terms-and-conditions/">Terms</Link> & <Link className='policy-link' to="/privacy-policy/"> Privacy Policy</Link>.
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </AnimationOnScroll>
    )
}

export default AreaContent