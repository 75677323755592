
import * as React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import _ from "lodash"
function NewsStructuredData({ description, pageurl, title, image, author, date }) {
  const site_url = process.env.GATSBY_SITE_URL;
  var ldJson = {
    "@context": "http://schema.org",
    "@type": "NewsArticle",
    "mainEntityOfPage": {
      "@type": "WebPage",
      "@id": `${pageurl}`
    },
    "headline": `${title}`,
    "image": [
      `"${image}"`
    ],
    "datePublished": `${date}`,
    "dateModified": `${date}`,
    "author": {
      "@type": "Person",
      "name": `${author? author : "Arabian Estates"}`
    },
    "publisher": {
      "@type": "Organization",
      "name": "Arabian Estates Real Estate Dubai",
      "logo": {
        "@type": "ImageObject",
        "url": "https://ggfx-arabianestates.s3.eu-west-2.amazonaws.com/i.prod/logo_black_67f0206787.png"
      }
    },
    "description": `${description}`
  }


  return (
    <Helmet>
      <script type="application/ld+json">
        {JSON.stringify(ldJson, null, 2)}
      </script>
    </Helmet>
  )
}


export default NewsStructuredData
