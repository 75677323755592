import React from 'react'
import { Link } from "gatsby"
import moment from 'moment';
import './RecentNews.scss'

import { OtherNews } from '../../common/queries/common_use_query';
import { NewsBaseURL } from "../../common/site/utils"

const RecentNews = (props) => {
  const newsData = props.newsData
  const { loading: loading, error: error, data: news_data } = OtherNews(props.id)


  return (
    <React.Fragment>
      {news_data && news_data.blogs && news_data.blogs.length > 0 ?
        <div className='recent-news'>
          <h4>Recent News</h4>
          {
            news_data && news_data.blogs.map((item, index) => {
              return <div key={index} className="news-content">
                <div className='ltl-news'>
                <Link to={`${NewsBaseURL}${item.slug}/`}>
                  <img src={item.tile_image.url} alt='news-img' />
                </Link>
                </div>
                <div className='rtl-news'>
                  <Link to={`${NewsBaseURL}${item.slug}/`}><h4>{item.title}</h4></Link>
                  <span className='data-card'>{moment(item.post_on).format("DD MMMM YYYY")}</span>
                </div>
              </div>
            })
          }
        </div> : ''}
    </React.Fragment>
  )
}

export default RecentNews